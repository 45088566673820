// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Numeral = require("numeral");
var FormatPrice = require("./formatPrice").default;

function formatTypeToStr(x) {
  if (typeof x !== "object") {
    if (x === "DollarAmount") {
      return "$0,0";
    } else {
      return "0,0";
    }
  } else {
    return x._0;
  }
}

function format(formatType, value) {
  return Numeral(value).format(formatTypeToStr(formatType));
}

function formatStripePrice(centsOptionalOpt, value) {
  var centsOptional = centsOptionalOpt !== undefined ? centsOptionalOpt : true;
  return Numeral(value / 100).format(centsOptional ? "0,0[.]00" : "0,0.00");
}

function formatPrice(extra) {
  return format("DollarAmount", extra);
}

function formatNumber(extra) {
  return format({
              TAG: "Custom",
              _0: "0,0"
            }, extra);
}

function _formatPrice(prim) {
  return FormatPrice(prim);
}

var formatForPriceRange = _formatPrice;

exports.format = format;
exports.formatStripePrice = formatStripePrice;
exports.formatPrice = formatPrice;
exports.formatNumber = formatNumber;
exports.formatForPriceRange = formatForPriceRange;
/* numeral Not a pure module */
